<template>
    <div class="InTest">
        <div class="container-left">
            <div>{{ subjectType[subject.type] }}（{{ score }}分)</div>
            <el-divider></el-divider>
            <div>{{ current }}、题目：{{ subject.title }}（ ）</div>
            <div class="answer">
                <div
                    class="flex-alc click mb-16"
                    style="width: max-content"
                    @click="radioCheck(item.value)"
                    v-for="(item, index) in subject.type == 2
                        ? [
                              { value: '1', title: '是' },
                              { value: '2', title: '否' },
                          ]
                        : subject.option"
                    :key="'answer' + index"
                >
                    <div
                        v-show="radio.indexOf(item.value) == -1"
                        class="option"
                    >
                        {{ subject.type == 2 ? "" : selectedType[index] }}
                    </div>
                    <img
                        v-show="radio.indexOf(item.value) != -1"
                        src="@/assets/gou.png"
                        class="select"
                        alt=""
                    />
                    <div class="option-text">{{ item.title }}</div>
                </div>
            </div>
            <div class="operation">
                <div
                    class="next click"
                    @click="current == 1 ? '' : switchSubject('up')"
                    :class="{ last: current == 1 }"
                >
                    上一题
                </div>
                <div
                    class="next click"
                    :class="{
                        last: current == subjectList.length,
                    }"
                    @click="
                        current == subjectList.length
                            ? ''
                            : switchSubject('next')
                    "
                >
                    下一题
                </div>
            </div>
        </div>
        <div class="container-right">
            <div class="time">
                倒计时：{{ minute > 9 ? minute : "0" + minute }}：{{
                    second > 9 ? second : "0" + second
                }}
            </div>
            <div class="answer-sheet">
                <div class="flex-alc between">
                    <div class="text">
                        答题卡（{{ current + "/" + subjectList.length }}）
                    </div>
                    <div class="flex-alc">
                        <div class="pass">及格分：</div>
                        <div class="fraction">{{ grade }}</div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="subject">
                    <div
                        class="num"
                        v-for="(item, index) in subjectList"
                        :key="'test' + index"
                        :class="{
                            'mr-2': (index + 1) % 10 != 0,
                            complete: index < answerList.length,
                            now: index == current - 1,
                        }"
                    >
                        {{ index + 1 }}
                    </div>
                </div>
                <div class="submit click" @click="submit">交卷</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InTest",
        data() {
            return {
                radio: [], //选中项
                subjectType: ["单选题", "多选题", "判断题", "填空题"],
                selectedType: ["A", "B", "C", "D", "E", "F"],
                current: 1, //当前第几题
                answer: {}, //答案
                answerList: [], //已完成答题的答案列表
                evaluation: {}, //测评对象
                subjectList: [], //全部问题
                minute: 0, //倒计时 分
                second: 0, //倒计时 秒
                time: null, //周期定时器
                id: 0, //测评id
                ifretest: 0, //0测评 1 重新测评
            };
        },
        computed: {
            score: function () {
                return this.subjectList[this.current - 1]?.score || 1;
            },
            subject: function () {
                return this.subjectList[this.current - 1]?.questions || {};
            },
            grade: function () {
                return this.evaluation.evaluation?.qualified;
            },
        },
        watch: {
            subject: function () {
                this.answer = {
                    id: this.subjectList[this.current - 1]?.id, //答案id
                    answer: "",
                };
            },
        },
        async mounted() {
            this.id = this.$route.query.test;
            this.ifretest = this.$route.query.ifretest || 0;
            await this.getList();
        },

        destroyed() {
            //清除定时器
            clearInterval(this.time);
            this.time = null;
            console.log("销毁页面清除倒计时");
        },
        methods: {
            //选择答案
            radioCheck(e) {
                let index = this.radio.indexOf(e);
                if (this.subject.type == 1) {
                    if (index != -1) {
                        this.radio.splice(index, 1);
                    } else {
                        this.radio.push(e);
                    }
                } else {
                    this.radio = [];
                    if (index == -1) {
                        this.radio.push(e);
                    }
                }
            },
            //上下题切换
            switchSubject(e) {
                if (e == "up") {
                    this.current > 1 ? this.current-- : this.current;
                    this.radio = this.answerList[this.current - 1].answer.split(
                        ","
                    );
                }
                if (e == "next") {
                    this.answer.answer = this.radio.join(",");
                    this.radio = [];
                    if (this.current > this.answerList.length) {
                        this.answerList.push(this.answer);
                    } else {
                        this.answerList[this.current - 1] = this.answer;
                    }
                    this.current < this.subjectList.length
                        ? this.current++
                        : this.current;

                    if (this.current - 1 < this.answerList.length) {
                        this.radio = this.answerList[this.current - 1].answer.split(
                            ","
                        );
                    }
                }
            },
            //获取题目
            getList() {
                this.$https
                    .get("/api/exam/evaluation/detail", {
                        id: this.id,
                        ifretest: this.ifretest,
                    })
                    .then((res) => {
                        if (res.errcode) return;
                        //题目列表
                        this.evaluation = res.data;
                        this.subjectList = [
                            ...this.subjectList,
                            ...res.data.test_list.completion,
                            ...res.data.test_list.judge,
                            ...res.data.test_list.multiple,
                            ...res.data.test_list.single,
                        ];
                        //考试时间
                        this.$router.replace({
                            path: "/InTest",
                            query: { test: this.id, ifretest: 0 },
                        });
                        this.start_time = res.data.now_time
                            .replace("-", "/")
                            .replace("-", "/");
                        this.qualified = res.data.evaluation.qualified || 0;
                        this.dealline = res.data.dealline
                            .replace("-", "/")
                            .replace("-", "/");
                        let start_date = new Date(this.start_time).getTime();
                        let end_date = new Date(this.dealline).getTime();
                        if (end_date - start_date < 1) {
                            this.$message.error(
                                "时间到，答题结束！",
                                this.start_time,
                                this.dealline
                            );
                            this.submit();
                            return console.log("测试时间已用完");
                        }
                        let all_time = (end_date - start_date) / 1000 || 0;
                        this.minute = (all_time / 60).toString().split(".")[0] || 0;
                        this.second = (all_time % 60).toString().split(".")[0] || 0;
                        //测试时间倒计时
                        this.time = setInterval(() => {
                            this.second--;
                            if (this.second < 0) {
                                if (this.minute > 0) {
                                    this.minute--;
                                    this.second = 59;
                                } else {
                                    clearInterval(this.time);
                                    this.time = null;
                                    this.second = 0;
                                    console.log("测试时间到,自动提交", new Date());
                                    this.submit();
                                }
                            }
                        }, 1000);
                    });
            },
            //交卷
            submit() {
                this.answer.answer = this.radio.join(",");
                this.answerList.push(this.answer);
                this.$https
                    .post("/api/exam/evaluation/submit", {
                        id: this.evaluation.evaluation.id,
                        data: this.answerList,
                    })
                    .then((res) => {
                        if (res.errcode) return this.$message.error(res.msg);
                        this.$router.replace({
                            path: "/testResult",
                            query: {
                                score: res.data.score, //成绩
                                qualified: res.data.evaluation.qualified, //及格分
                                paperScore: res.data.evaluation.score, //总分
                                test: this.id, //测评id
                                paper: this.evaluation.id, //试卷id
                                ifanalysis: res.data.evaluation.ifanalysis, //是否显示错题解析
                            },
                        });
                    });
            },
        },
    };
</script>

<style scoped lang="less">
.InTest {
    width: 1200px;
    margin: auto;
    margin-top: 37px;
    margin-bottom: 157px;
    display: flex;
    justify-content: space-between;
    .container-left {
        width: 850px;
        background: #ffffff;
        border-radius: 15px;
        padding: 18px 25px;
        .el-divider--horizontal {
            margin-top: 16px;
            margin-bottom: 30px;
        }
        .answer {
            margin-top: 55px;
            .option {
                width: 25px;
                height: 25px;
                background: #f6f6f6;
                border: 1px solid #e1e1e1;
                font-size: 16px;
                font-weight: 400;
                color: #2d2d2d;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
            .select {
                width: 25px;
                height: 25px;
                border-radius: 100%;
            }
            .option-text {
                font-size: 16px;
                font-weight: 400;
                color: #2d2d2d;
                margin-left: 10px;
            }
        }
        .operation {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 170px;
            margin-bottom: 13px;
            .next {
                width: 100px;
                height: 33px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 21px;
            }
            .last {
                background: #949494;
            }
        }
    }
    .container-right {
        width: 328px;
        background: #ffffff;
        border-radius: 15px;
        height: max-content;
        .time {
            background: linear-gradient(180deg, #ff3764, #ff2604);
            border-radius: 15px 15px 0px 0px;
            padding: 20px;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
        }
        .answer-sheet {
            padding: 18px 25px 28px 25px;
            .text {
                font-size: 16px;
                font-weight: 400;
                color: #2d2d2d;
            }
            .pass {
                font-size: 16px;
                font-weight: 400;
                color: #2d2d2d;
            }
            .fraction {
                font-size: 16px;
                font-weight: 400;
                color: #ff2d2d;
            }
            .el-divider--horizontal {
                margin-top: 15px;
                margin-bottom: 18px;
            }
            .subject {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .num {
                    width: 26px;
                    height: 26px;
                    background: #fff;
                    border: 1px solid #dfdfdf;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                    margin-bottom: 9px;
                }
                .complete {
                    background: #f1f1f1;
                }
                .now {
                    background: #ffd9d9;
                }
            }
            .submit {
                width: 180px;
                height: 33px;
                background: linear-gradient(180deg, #ff3764, #ff2604);
                border-radius: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 600;
                color: #ffffff;
                margin: 38px auto 0px auto;
            }
        }
    }
}
</style>