var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InTest"},[_c('div',{staticClass:"container-left"},[_c('div',[_vm._v(_vm._s(_vm.subjectType[_vm.subject.type])+"（"+_vm._s(_vm.score)+"分)")]),_c('el-divider'),_c('div',[_vm._v(_vm._s(_vm.current)+"、题目："+_vm._s(_vm.subject.title)+"（ ）")]),_c('div',{staticClass:"answer"},_vm._l((_vm.subject.type == 2
                    ? [
                          { value: '1', title: '是' },
                          { value: '2', title: '否' } ]
                    : _vm.subject.option),function(item,index){return _c('div',{key:'answer' + index,staticClass:"flex-alc click mb-16",staticStyle:{"width":"max-content"},on:{"click":function($event){return _vm.radioCheck(item.value)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.radio.indexOf(item.value) == -1),expression:"radio.indexOf(item.value) == -1"}],staticClass:"option"},[_vm._v(" "+_vm._s(_vm.subject.type == 2 ? "" : _vm.selectedType[index])+" ")]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.radio.indexOf(item.value) != -1),expression:"radio.indexOf(item.value) != -1"}],staticClass:"select",attrs:{"src":require("@/assets/gou.png"),"alt":""}}),_c('div',{staticClass:"option-text"},[_vm._v(_vm._s(item.title))])])}),0),_c('div',{staticClass:"operation"},[_c('div',{staticClass:"next click",class:{ last: _vm.current == 1 },on:{"click":function($event){_vm.current == 1 ? '' : _vm.switchSubject('up')}}},[_vm._v(" 上一题 ")]),_c('div',{staticClass:"next click",class:{
                    last: _vm.current == _vm.subjectList.length,
                },on:{"click":function($event){_vm.current == _vm.subjectList.length
                        ? ''
                        : _vm.switchSubject('next')}}},[_vm._v(" 下一题 ")])])],1),_c('div',{staticClass:"container-right"},[_c('div',{staticClass:"time"},[_vm._v(" 倒计时："+_vm._s(_vm.minute > 9 ? _vm.minute : "0" + _vm.minute)+"："+_vm._s(_vm.second > 9 ? _vm.second : "0" + _vm.second)+" ")]),_c('div',{staticClass:"answer-sheet"},[_c('div',{staticClass:"flex-alc between"},[_c('div',{staticClass:"text"},[_vm._v(" 答题卡（"+_vm._s(_vm.current + "/" + _vm.subjectList.length)+"） ")]),_c('div',{staticClass:"flex-alc"},[_c('div',{staticClass:"pass"},[_vm._v("及格分：")]),_c('div',{staticClass:"fraction"},[_vm._v(_vm._s(_vm.grade))])])]),_c('el-divider'),_c('div',{staticClass:"subject"},_vm._l((_vm.subjectList),function(item,index){return _c('div',{key:'test' + index,staticClass:"num",class:{
                        'mr-2': (index + 1) % 10 != 0,
                        complete: index < _vm.answerList.length,
                        now: index == _vm.current - 1,
                    }},[_vm._v(" "+_vm._s(index + 1)+" ")])}),0),_c('div',{staticClass:"submit click",on:{"click":_vm.submit}},[_vm._v("交卷")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }